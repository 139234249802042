import { getAwsPersonalizeProductsV2 } from "@/services/global/aws-personalize";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import store from "store";
import useSWR from "swr";
import { getProductsBySkuV2 } from "@/services/elasticsearch/getProducts";
import { selectedAtteributes } from "@/utils/product";
import { getCookie } from "cookies-next";
import Cookies from "js-cookie";

// integrated directly with aws to get products accepts recommenderCode and extraPayload if needed
const useGetAwsPersonalizeProducts = (recommenderCode, extraPayload) => {
  const { data: session } = useSession();
  const { locale } = useRouter();
  const handshake = getCookie("handshake");
  const region_id = Cookies.get("region_id");

  const regionId =
    region_id && region_id != "NaN" && region_id != "undefined"
      ? region_id
      : "1101";

  const sessionId = store.get("sessionId") || "";

  const recommenderId = String(session?.user?.id || sessionId);

  const { data: productsSkuData, isLoading } = useSWR(
    [
      "aws-personalize-products-sku",
      locale,
      recommenderId,
      recommenderCode,
      extraPayload,
      regionId,
    ],
    () =>
      getAwsPersonalizeProductsV2(recommenderId, recommenderCode, extraPayload),
    {
      revalidateOnFocus: false,
      revalidateOnMount: !!recommenderId,
      revalidateIfStale: true,
      dedupingInterval: 0,
    }
  );

  const productsSku = [
    ...new Set(productsSkuData?.itemList?.map((prod) => prod?.itemId) || []),
  ];

  const { data: productsData } = useSWR(
    ["aws-personalize-products", locale, productsSku, recommenderCode],
    () =>
      productsSku?.length > 0
        ? getProductsBySkuV2(productsSku, handshake, selectedAtteributes)
        : {},
    {
      revalidateOnFocus: false,
      revalidateOnMount: false,
      revalidateIfStale: true,
      dedupingInterval: 0,
    }
  );

  const products = productsData?.data?.map(({ _source }) => _source) || [];

  return { products, isLoading };
};

export default useGetAwsPersonalizeProducts;
