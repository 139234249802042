import { getHomePageData } from "@/services/home";
import { Toaster } from "react-hot-toast";
import { Fragment, useEffect } from "react";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useSession } from "next-auth/react";
import { event_user_logged_in } from "@/utils/gtm";
import { getCookie, setCookie } from "cookies-next";
import { CookieExpireAfter } from "@/utils/global/CookieExpire";
import { deviceSwitcherKey, renderHomeJson } from "@/utils/home";
import { SWRConfig } from "swr";
import SEO from "@/components/seo";
import { handshakeCookieName } from "@/constants";
import { localeHandShake } from "@/services/token/handshake";

export default function HomePage({ home, isMobile, updatedHandshake }) {
  const session = useSession();
  const {
    t,
    i18n: { language: lang },
  } = useTranslation("home");

  const isLoggedIn = getCookie("lgn");
  useEffect(() => {
    if (session?.data && isLoggedIn !== "webengage") {
      const user = session?.data?.user;
      const { firstname, lastname, custom_attributes, email } = user;
      const mobile =
        custom_attributes?.find(
          (item) => item?.attribute_code === "mobilenumber"
        )?.value || null;

      setTimeout(() => {
        event_user_logged_in(firstname, lastname, mobile, email, null);
        setCookie("lgn", "webengage", { expires: CookieExpireAfter(20) });
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  // Client Data
  const homeSchema = {
    "@context": "https://schema.org",
    "@type": "Corporation",
    name: "Almanea",
    alternateName: "المنيع",
    url: "https://www.almanea.sa/",
    logo: "https://images-r2.dev-almanea.com/almanea-logo.webp",
    sameAs: [
      "https://www.facebook.com/halmanea",
      "https://twitter.com/AlManeaCo",
      "https://www.instagram.com/almaneaco/",
      "https://youtube.com/@AlmaneaCo?si=WTbJ5NxuYH4LpwM3",
    ],
  };

  useEffect(
    () => setCookie("handshake", updatedHandshake),
    [updatedHandshake, lang]
  );

  return (
    <>
      <SEO>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(homeSchema) }}
        />
      </SEO>
      <SWRConfig value={{ provider: () => new Map() }}>
        <section>
          <Toaster position="bottom-center" reverseOrder />
          {home?.length > 0 &&
            home.map((section, i) => (
              <Fragment key={`${section?.key}-${i}`}>
                {renderHomeJson(section, isMobile, t, lang, updatedHandshake)}
              </Fragment>
            ))}
        </section>
      </SWRConfig>
    </>
  );
}

export async function getServerSideProps({ locale, req, res }) {
  const userAgent = req.headers["user-agent"] || "";
  const isMobile =
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/i.test(
      userAgent
    );
  const handshake =
    req.cookies[handshakeCookieName(locale)] ||
    (await localeHandShake(locale, { req, res }))?.data?.token;
  const home = (await getHomePageData(handshake))?.data?.at(0) || {};
  const aws = (home?.data || [])
    ?.filter(
      (sec) => sec?.key === "aws" && sec[`${isMobile ? "mobile-" : ""}active`]
    )
    .sort(
      (a, b) =>
        a[`${isMobile ? "mobile-" : ""}position`] -
        b[`${isMobile ? "mobile-" : ""}position`]
    );
  const sortedHome = (
    Array.isArray(home?.data)
      ? [
          ...home?.data,
          {
            key: "aws_tabs",
            position: aws?.at(0)?.position || 10000,
            "mobile-position": aws?.at(0)?.["mobile-position"] || 10000,
            active: aws?.at(0)?.active || false,
            "mobile-active": aws?.at(0)?.["mobile-active"] || false,
            "background-color": aws?.at(0)?.["background-color"] || "",
            value: aws || [],
          },
        ]
      : []
  )
    ?.filter((sec) => sec[`${isMobile ? "mobile-" : ""}active`])
    .sort(
      (a, b) =>
        a[`${isMobile ? "mobile-" : ""}position`] -
        b[`${isMobile ? "mobile-" : ""}position`]
    )
    .map((sec) => ({
      ...sec,
      value: sec?.value
        ? sec.value
            .filter((sub) => sub[deviceSwitcherKey("active", isMobile)])
            .sort(
              (a, b) =>
                a[deviceSwitcherKey("position", isMobile)] -
                b[deviceSwitcherKey("position", isMobile)]
            )
        : [],
    }));

  return {
    props: {
      isMobile,
      // updatedHandshake: getToken,
      updatedHandshake: handshake,
      home: sortedHome || [],
      ...(await serverSideTranslations(locale, ["home", "common"])),
    },
  };
}
