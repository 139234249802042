// used in Category Components in Home Page
export const CategoriesBreakPoints = {
  320: {
    slidesPerView: 2.3,
  },
  350: {
    slidesPerView: 2.5,
  },
  460: {
    slidesPerView: 3.2,
  },
  570: {
    slidesPerView: 3.5,
  },
  730: {
    slidesPerView: 4.5,
  },
  991: {
    slidesPerView: 5,
  },
  1200: {
    slidesPerView: 6,
  },
  1600: {
    slidesPerView: 7,
  },
};

// used in Today's Offer Components in Home Page
export const TodayOfferBreakPoints = {
  380: {
    slidesPerView: 1.1,
  },
  450: {
    slidesPerView: 1.5,
  },
  580: {
    slidesPerView: 1.7,
  },
  700: {
    slidesPerView: 2.2,
  },
  851: {
    slidesPerView: 2.5,
  },
  1200: {
    slidesPerView: 3,
  },
};

// used in Prodduct Component in Home Page

export const ProductByBreakPoints = {
  336: {
    slidesPerView: 1.5,
    slidesPerGroup: 1,
  },
  436: {
    slidesPerView: 1.7,
    slidesPerGroup: 1,
  },
  520: {
    slidesPerView: 2.3,
    slidesPerGroup: 2,
  },
  666: {
    slidesPerView: 2.7,
    slidesPerGroup: 2,
  },
  768: {
    slidesPerView: 3.2,
    slidesPerGroup: 3,
  },
  851: {
    slidesPerView: 3.5,
    slidesPerGroup: 3,
  },
  991: {
    slidesPerView: 4.005,
    slidesPerGroup: 4,
  },
  1200: {
    slidesPerView: 5,
    slidesPerGroup: 5,
  },
};
export const hotProductsBreakPoints = (isFullWidth) => ({
  336: {
    slidesPerView: !isFullWidth ? 1.7 : 1.5,
    slidesPerGroup: 1,
  },
  436: {
    slidesPerView: 1.7,
    slidesPerGroup: 1,
  },
  520: {
    slidesPerView: 1.5,
    slidesPerGroup: 2,
  },
  // 666: {
  //   slidesPerView: 2.7,
  //   slidesPerGroup: 2,
  // },
  768: {
    slidesPerView: isFullWidth ? 3 : 1.5,
    slidesPerGroup: isFullWidth ? 4 : 3,
  },
  // 851: {
  //   slidesPerView: isFullWidth ? 4 : 3,
  //   slidesPerGroup: isFullWidth ? 4 : 3,
  // },
  991: {
    slidesPerView: isFullWidth ? 3 : 1.7,
    slidesPerGroup: isFullWidth ? 4 : 3,
  },
  1200: {
    slidesPerView: isFullWidth ? 4 : 3,
    slidesPerGroup: isFullWidth ? 4 : 3,
  },
});

// used in Brands Components in Home Page
export const BrandsBreakPoints = {
  320: {
    // slidesPerView: 1.5,
    slidesPerView: 3,
  },
  380: {
    // slidesPerView: 2.4,
    slidesPerView: 3,
  },
  570: {
    slidesPerView: 3.4,
  },
  768: {
    slidesPerView: 4.4,
  },
  991: {
    slidesPerView: 5.4,
  },
  1200: {
    slidesPerView: 6,
  },
};

export const BrandsBreakPointsNew = {
  320: {
    slidesPerView: 1.25,
  },
  380: {
    slidesPerView: 2.1,
  },
  570: {
    slidesPerView: 3,
  },
  768: {
    slidesPerView: 3.9,
  },
  991: {
    slidesPerView: 4.5,
  },
  1200: {
    slidesPerView: 5,
  },
};

// used in Product Detail Page
export const Style = {
  "--swiper-pagination-bullet-inactive-color": "#d1d5db",
  "--swiper-pagination-bullet-inactive-opacity": "1",
  "--swiper-pagination-bullet-size": "16px",
  "--swiper-pagination-bottom": "-5px",
};

export const PDPBreakPoints = {
  320: {
    slidesPerView: 1,
    pagination: {
      enabled: true,
      clickable: true,
    },
  },
  450: {
    slidesPerView: 1.5,
    pagination: {
      enabled: true,
      clickable: true,
    },
  },
  769: {
    slidesPerView: 2,
    pagination: {
      enabled: true,
      clickable: true,
    },
  },
  992: {
    slidesPerView: 5,
    pagination: {
      enabled: false,
    },
  },
};

// Order Component Break Points
export const OrderBreakPoints = {
  320: {
    slidesPerView: 1.2,
  },
  420: {
    slidesPerView: 1.4,
  },
  570: {
    slidesPerView: 2.3,
  },
  992: {
    slidesPerView: 1.2,
  },
};

// cardInfo break points
export const cardInfoBreakPoints = {
  320: {
    slidesPerView: 1,
  },
  420: {
    slidesPerView: 1.4,
  },
  768: {
    slidesPerView: 2,
  },
};
