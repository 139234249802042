export default function Container({ className, children }) {
  return (
    <div
      className={`w-[80%] max-[991px]:w-[98%] mx-auto relative ${
        className || ""
      } `}
    >
      {children}
    </div>
  );
}
