import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import ImgTag from "../Helpers/ImgTag";
import { validateUrl, deviceSwitcherKey } from "@/utils/home";

// Function to check if a banner is active
const isBannerActive = (banner, isMobile) => {
  const isActive = banner?.[isMobile ? "mobile-active" : "active"];
  // Get the current date and convert it to UTC+3
  const currentDate = new Date(
    new Date().toLocaleString("en-US", { timeZone: "Asia/Riyadh" })
  );
  const startDate = new Date(banner["start-date"]?.replace(" ", "T"));
  const endDate = new Date(banner["end-date"]);

  console.log("currentDate_______", +banner.position, {
    now: currentDate.getTime(),
    startDate: startDate.getTime(),
    endDate: endDate.getTime(),
    isActive:
      currentDate.getTime() >= startDate.getTime() &&
      currentDate.getTime() <= endDate.getTime(),
  });
  return (
    isActive &&
    currentDate.getTime() >= startDate.getTime() &&
    currentDate.getTime() <= endDate.getTime()
  );
};
const MainSlider2 = ({ data, isMobile }) => {
  const { locale } = useRouter();
  const position = isMobile ? "mobile-position" : "position";
  const slides = (Array.isArray(data.value) ? data.value : [])
    ?.filter((slide) => isBannerActive(slide, isMobile))
    ?.sort((a, b) => +a?.[position] - +b?.[position]);
  console.log("slides______ after", slides?.length, data?.value?.length);
  console.log("slides_______before", data?.value);
  return <Carousel {...{ slides, isMobile, locale }} />;
};

MainSlider2.displayName = "BannerSwiper";
export default MainSlider2;

const Carousel = ({ slides, isMobile, locale }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [direction, setDirection] = useState("next");
  const [startX, setStartX] = useState(0);
  const [deltaX, setDeltaX] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      handleNext();
    }, 5000);

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlide]);

  const handleNext = () => {
    setDirection("next");
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  const handlePrev = () => {
    setDirection("prev");
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const goToSlide = (index) => {
    setDirection(index > currentSlide ? "next" : "prev");
    setCurrentSlide(index);
  };

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setDeltaX(e.touches[0].clientX - startX);
  };

  const handleTouchEnd = () => {
    if (deltaX > 50) handlePrev();
    if (deltaX < -50) handleNext();
    setDeltaX(0);
  };

  const getSlideStyles = (index) => {
    const isActive = currentSlide === index;
    const isNext =
      direction === "next" && index === (currentSlide + 1) % slides.length;
    const isPrev =
      direction === "prev" &&
      index === (currentSlide - 1 + slides.length) % slides.length;

    const baseStyles = {
      opacity: 0.1,
      transform: "scale(1)",
      transition: "opacity .5s ease-out, transform -.5s ease-out",
      zIndex: 0,
    };

    if (isActive) {
      return {
        opacity: 1,
        transform: "scale(1)",
        transformOrigin: "left center",
        transition: "opacity 1.2s ease-in, transform 2s ease-out",
        zIndex: 2,
      };
    }

    if (isNext) {
      return {
        opacity: 0.3,
        transform: "scale(1.15)",
        transformOrigin: "right center",
        transition: "opacity 2.5s ease-in, transform 2.5s ease-in",
        zIndex: 1,
      };
    }

    if (isPrev) {
      return {
        opacity: 0,
        transformOrigin: "left center",
        transform: "scale(1.1)",
        transition: "opacity 1.5s ease-out, transform 1.5s ease-out",
        zIndex: 1,
      };
    }

    return baseStyles;
  };

  return (
    <div
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      className="relative w-full bg-black/80 mx-auto max-[768px]:aspect-[6/4] aspect-[18/5] overflow-hidden group"
    >
      {/* Slides */}
      {slides.map((slide, index) => {
        const url = validateUrl(slide.url, slide.type);
        return (
          <Link
            href={url}
            key={"main-slider-" + index}
            className={`absolute w-full h-full transition-all duration-700 ease-in-out`}
            style={{
              ...getSlideStyles(index),
            }}
          >
            <ImgTag
              src={slide?.[deviceSwitcherKey("img", isMobile, locale)]}
              alt={slide.title}
              className="object-cover w-full h-full"
            />
          </Link>
        );
      })}

      {/* Navigation Arrows */}
      <div
        className={`group-hover:opacity-100 opacity-0 transition-opacity duration-200 max-[570px]:hidden block`}
      >
        <button
          onClick={() => (locale === "ar" ? handleNext() : handlePrev())}
          className="absolute z-10 p-4 text-white transition-all -translate-y-1/2 rounded-full left-4 top-1/2 bg-black/50 hover:bg-black/75"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-4 h-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>

        <button
          onClick={() => (locale === "en" ? handleNext() : handlePrev())}
          className="absolute z-50 p-4 text-white transition-all -translate-y-1/2 rounded-full right-4 top-1/2 bg-black/50 hover:bg-black/75"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-4 h-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
      </div>

      {/* Dots Navigation */}
      <div className="absolute z-10 flex gap-2 -translate-x-1/2 bottom-4 left-1/2">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={`max-[768px]:size-2 w-3 h-3 rounded-full transition-all duration-300 ${
              currentSlide === index
                ? "bg-white max-[768px]:w-4 w-6"
                : "bg-white/50 hover:bg-white/75"
            }`}
          />
        ))}
      </div>
    </div>
  );
};
