import ImgTag from "@/components/Helpers/ImgTag";
import { deviceSwitcherKey } from "@/utils/home";
import Link from "next/link";
import { useRouter } from "next/router";

const CategoriesGallery = ({ data, isMobile }) => {
  const electronic = data?.value || [];
  const filterd = [true, true, false, false, true, true, false, false];
  const { locale } = useRouter();
  return (
    <div className="w-[80%] max-[991px]:w-[95%] my-5 mx-auto grid grid-cols-4 991px:grid-cols-2 max-[470px]:grid-cols-1 gap-3">
      {[0, 2, 4, 6].map((i) => (
        <div
          key={i}
          className={`flex ${
            filterd[i] ? "flex-col" : "flex-col-reverse"
          } gap-y-3`}
        >
          <Link
            href={`${electronic[i]?.url}` || "/#"}
            className="border rounded-md cursor-pointer border-sky-300"
          >
            <div className="relative w-full overflow-hidden aspect-square max-[470px]:aspect-[3/2]">
              <ImgTag
                loading="eager"
                src={electronic[i][deviceSwitcherKey("img", isMobile, locale)]}
                className="object-contain w-full h-full"
                fill="true"
                sizes="(min-width: 1700px) 356px, (min-width: 1000px) calc(20.29vw + 15px), (min-width: 480px) 45.8vw, 91.88vw"
                alt="Electronic Img!"
              />
            </div>
            <p className="whitespace-nowrap text-blue-800 w-full bg-white font-semibold text-center py-1.5 text-lg">
              {electronic[i][`title-${locale}`]}
            </p>
          </Link>
          <Link
            href={`${electronic[i + 1]?.url}` || "/#"}
            className="border rounded-md cursor-pointer border-sky-300"
          >
            <div className="w-full aspect-[1/0.8] max-[470px]:aspect-[3/2] overflow-hidden relative">
              <ImgTag
                loading="eager"
                src={
                  electronic[i + 1][deviceSwitcherKey("img", isMobile, locale)]
                }
                className="object-contain w-full h-full"
                fill="true"
                sizes="(min-width: 1700px) 356px, (min-width: 1000px) calc(20.29vw + 15px), (min-width: 480px) 45.8vw, 91.88vw"
                alt="Electronic Img!"
              />
            </div>
            <p className="whitespace-nowrap text-blue-800 w-full bg-white font-semibold text-center py-1.5 text-lg">
              {electronic[i + 1][locale === "en" ? "title-en" : "title-ar"]}
            </p>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default CategoriesGallery;
