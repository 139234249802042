const ProductCardSkeleton = () => (
  <div class="animate-pulse bg-white rounded-lg border p-3 relative max-w">
    <div className="absolute top-3 start-3 size-10 rounded-full bg-gray-200" />
    <div className="flex justify-center items-center h-48">
      <svg
        class="size-20 text-gray-200 dark:text-gray-600"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 18"
      >
        <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
      </svg>
    </div>
    <div class="w-12 h-6 bg-gray-300 rounded-md" />

    <div class="h-4 w-full bg-gray-300 rounded-md mt-2" />
    <div class="h-4 w-3/4 bg-gray-200 rounded-md mt-2" />

    <div className="flex justify-center items-center">
      <div class="h-6 w-1/3 bg-gray-300 rounded-md my-6" />
    </div>
    <div class="flex gap-4 justify-between mt-2">
      <div class="w-3/4 h-10 bg-gray-300 rounded-full" />
      <div class="size-10 bg-gray-200 rounded-full " />
    </div>
  </div>
);
export default ProductCardSkeleton;
