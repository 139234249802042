import Container from "./Container";
import { useRouter } from "next/router";
import { deviceSwitcherKey, validateUrl } from "@/utils/home";
import Link from "next/link";
import NextImage from "../NextImage";
import { validateImgSrc } from "@/utils/global";

export default function HotCategoriesList({ data, isMobile }) {
  const sections = data?.value?.length;
  return (
    <Container className="my-10 max-md:my-4">
      <div
        className={`grid grid-cols-${sections} max-[570px]:grid-cols-1 gap-4 max-md:gap-2 bg-neutral-100 p-4 max-md:p-2 rounded-lg`}
      >
        {data?.value?.map((category, idx) => (
          <HotCategoriesListDualDesign
            data={category}
            sections={sections}
            isMobile={isMobile}
            key={idx}
          />
        ))}
      </div>
    </Container>
  );
}

export function HotCategoriesListDualDesign({ data, isMobile, sections }) {
  const { locale } = useRouter();
  const img = data[deviceSwitcherKey("img", isMobile, locale)];
  return (
    <div
      className={`${
        sections === 1
          ? `grid ${
              img ? "grid-cols-6" : "grid-cols-1"
            } gap-4 max-[768px]:gap-0 max-[768px]:grid-cols-2 max-[768px]:content-start`
          : "grid grid-cols-4 gap-4 max-[768px]:gap-0 max-[768px]:grid-cols-2 max-[768px]:content-start"
      } items-center`}
    >
      {img ? (
        <Link
          href={`/${validateUrl(data?.url, data?.type)}`}
          className="h-full col-span-2"
        >
          <NextImage
            src={validateImgSrc(img)}
            alt={data[`title-${locale}`]}
            className="w-full h-full"
            width={500}
            height={500}
            quality={100}
          />
        </Link>
      ) : null}
      <div
        className={`max-[768px]:mt-0 ${
          sections === 1 ? "col-span-4 max-[768px]:col-span-2" : "col-span-2"
        } grid ${
          sections === 1
            ? "grid-cols-4 max-[768px]:grid-cols-2"
            : "grid-cols-2 max-[768px]:grid-cols-2"
        } gap-4 max-md:gap-2`}
      >
        {sections === 2 && (
          <h2 className="col-span-2 font-bold text-center max-[570px]:hidden">
            {data[`title-${locale}`] || ""}
          </h2>
        )}
        {data?.value?.map(
          (category, idx) =>
            category[deviceSwitcherKey("active", isMobile)] && (
              <Link
                href={`/${validateUrl(category?.url, category?.type)}`}
                className="col-span-1 p-4 overflow-hidden bg-white rounded-lg max-md:p-2"
                key={category[`title-${locale}`] + idx}
              >
                <NextImage
                  src={validateImgSrc(
                    category[deviceSwitcherKey("img", isMobile, locale)]
                  )}
                  alt={category[`title-${locale}`]}
                  className="object-contain w-full h-full transition-all duration-500 hover:scale-110"
                  width={500}
                  height={500}
                  quality={100}
                />
              </Link>
            )
        )}
      </div>
    </div>
  );
}
