import { TodayOfferBreakPoints } from "@/constants/BreakPoints";
import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useRouter } from "next/router";

import AddtoCart from "@/components/Helpers/AddtoCart";
import { getCookies } from "cookies-next";
import ImgTag from "@/components/Helpers/ImgTag";
import AddToCompare from "@/components/compare/AddToCompare";
import { getProductsByCategoryIdV2 } from "@/services/category/getProductsData";
import useSWR from "swr";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import NextImage from "@/components/NextImage";
import { validateImgSrc } from "@/utils/global";

function TodaysOffer({ data, t }) {
  const d = data?.value?.at(0);
  const [swiper, set_swiper] = useState({});
  const { push, locale } = useRouter();
  const { handshake, region_id } = getCookies();

  const { data: secData } = useSWR(
    `${d?.url}-${locale}-${region_id || 1101}`,
    async () =>
      getProductsByCategoryIdV2(
        handshake,
        d?.id,
        locale,
        "position",
        "ASC",
        region_id || 1101,
        0,
        9
      ),
    {
      revalidateOnFocus: false,
      refreshInterval: 30 * 60 * 1000,
    }
  );

  return (
    secData?.products?.length > 0 && (
      <div className="my-10 max-[570px]:mb-4 todays-offer-section ">
        <div className="w-[80%] max-[991px]:w-[98%] mx-auto flex items-center justify-between mb-4">
          <p className="text-xl font-semibold text-red">
            {d[`title-${locale}`]}
          </p>
          <div
            className={`flex items-center ${
              locale === "en" ? "flex-row" : "flex-row-reverse"
            }`}
          >
            <IoIosArrowBack
              onClick={() => swiper.slidePrev()}
              size="27px"
              className="cursor-pointer max-[991px]:hidden"
            />
            <IoIosArrowForward
              onClick={() => swiper.slideNext()}
              size="27px"
              className="cursor-pointer max-[991px]:hidden"
            />
          </div>
        </div>
        <div className="relative py-10">
          <div className="absolute top-0 left-0 w-full -z-20 max-[570px]:hidden">
            <div className="relative overflow-hidden w-full h-[450px]">
              <ImgTag
                loading="eager"
                sizes="(min-width: 1700px) 1280px, (min-width: 1000px) calc(70.59vw + 94px), 97.94vw"
                src="https://images-r2.dev-almanea.com/cdn-cgi/image/w=1900&h=300,fit=contain,format=auto,quality=75/bg.webp"
                className="object-cover w-full h-[450px]"
                height={450}
                width={800}
                alt="BG IMG!"
              />
            </div>
          </div>
          <div className="w-[80%] max-[991px]:w-[98%] mx-auto relative z-20">
            <Swiper
              onInit={(e) => set_swiper(e)}
              spaceBetween={10}
              breakpoints={TodayOfferBreakPoints}
              dir={locale === "en" ? "rtl" : "ltr"}
              key={locale}
              className="z-0"
            >
              {secData?.products?.map((product, i) => {
                return (
                  <SwiperSlide key={i}>
                    <TodaysOfferItem
                      {...{
                        i,
                        product: product?._source,
                        locale,
                        t,
                        push,
                      }}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>
    )
  );
}

export default TodaysOffer;

export const TodaysOfferItem = ({ product, locale, t, push }) => {
  const [mainImage, setmainImage] = useState();
  const [sideImages, setsideImages] = useState();

  useEffect(() => {
    if (product) {
      setmainImage(
        `${process.env.NEXT_PUBLIC_IMAGE_BASEURL}/media/catalog/product/${
          product?.image ? product?.image[0] : ""
        }`
      );
      let ArrOfSideImages = product?._media_?.image
        ?.slice(0, 3)
        .map((image) => {
          return image.image;
        });

      setsideImages([...ArrOfSideImages]);
    }
  }, [product]);

  const handleImageChange = (srcOfNewImage, indexOfNewImage) => {
    let newFilteredSideImages = sideImages.filter((_, i) => {
      return i !== indexOfNewImage;
    });

    setsideImages([mainImage, ...newFilteredSideImages]);
    setmainImage(srcOfNewImage);
  };
  console.log("product", product);
  return (
    <div className="todays-offer-item border bg-white p-3 rounded-lg min-h-[360px] inner-swiper-div flex flex-col justify-between relative">
      {product?.label &&
        product?.label[0]?.status === "1" &&
        new Date(product?.label[0]?.active_from) <
          new Date() <
          new Date(product?.label[0]?.active_to) && (
          <>
            <div className="absolute top-0 z-30 -translate-x-1/2 left-1/2">
              {/* <ImgTag
                  loading="eager"
                  src={`${process.env.NEXT_PUBLIC_IMAGE_BASEURL}/media/amasty/amlabel/${product?.label[0]?.frontend_settings.image}`}
                  className="w-full h-10"
                  height={40}
                  width={200}
                  alt="Img!"
                /> */}
              <NextImage
                src={validateImgSrc(
                  `${process.env.NEXT_PUBLIC_IMAGE_BASEURL}/media/amasty/amlabel/${product?.label[0]?.frontend_settings.image}`
                )}
                className="object-contain h-8 w-52"
                height={105}
                width={209}
                alt="Img !"
              />
            </div>
            {product?.label[0]?.frontend_settings.label_text != "" && (
              <div className="absolute top-[-4.5px] left-1/2 -translate-x-1/2">
                <span className="text-white text-[10px] font-[500]">
                  {product?.label[0]?.frontend_settings.label_text}
                </span>
              </div>
            )}
          </>
        )}
      <div className="upper">
        <h4
          className={` text-zinc-500 font-semibold text-sm ${
            locale == "en" ? "text-left" : "text-right"
          } `}
        >
          {product?.option_text_brand}
        </h4>
        <h3
          className={`text-zinc-700 line-clamp-1 cursor-pointer text-end`}
          onClick={
            product?.rewrite_url
              ? () => {
                  push(
                    `/product/${product.rewrite_url}?gtm=homepage&event=select_promotion`
                  );
                }
              : null
          }
        >
          {product.name[0]}
        </h3>
        <div className="flex justify-between my-3">
          <div className="relative w-3/4 overflow-hidden">
            {mainImage && (
              <div className="relative w-full overflow-hidden aspect-[3/2]">
                <NextImage
                  onClick={
                    product?.rewrite_url
                      ? () => {
                          push(
                            `/product/${product.rewrite_url}?gtm=homepage&event=select_promotion`
                          );
                        }
                      : null
                  }
                  src={validateImgSrc(mainImage)}
                  className="object-contain w-full h-auto cursor-pointer"
                  priority
                  width={800}
                  height={600}
                  alt="Img !"
                />
              </div>
            )}
          </div>
          <div className="flex flex-col items-end w-1/4 mx-1">
            {sideImages &&
              sideImages.slice(0, 2).map((img, i) => (
                <div
                  className={`w-full aspect-[1/0.8] overflow-hidden relative ${
                    i === 1 && "my-1"
                  }`}
                  key={i}
                >
                  <NextImage
                    priority
                    onClick={() => handleImageChange(img, i)}
                    src={validateImgSrc(img)}
                    height={60}
                    width={120}
                    className="w-full h-auto"
                    alt=""
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="lower">
        <div className="flex items-center justify-between">
          <div className="flex items-center max-[420px]:justify-between max-[570px]:block max-[420px]:w-full max-[420px]:mb-1">
            <h4 className="text-lg font-semibold line-clamp-1 text-red">
              {product?.prices_with_tax?.price?.toFixed(2)} {t("SAR")}
            </h4>

            {product?.prices_with_tax?.price ==
              product?.prices_with_tax?.original_price ||
            product?.prices_with_tax?.discounted_price_to == "" ? null : (
              <h4 className="mx-2 text-sm font-semibold line-through line-clamp-1 text-zinc-500 max-[570px]:mx-0">
                {product?.prices_with_tax?.original_price?.toFixed(2)}{" "}
                {t("SAR")}
              </h4>
            )}
          </div>

          <div className="flex items-center justify-between gap-2 ">
            <AddToCompare product={product} />
            <AddtoCart {...{ product, t }} />
          </div>
        </div>
      </div>
    </div>
  );
};
