import AWS from 'aws-sdk'

AWS.config.update({
  region: process.env.NEXT_PUBLIC_AWS_REGION,
  credentials: new AWS.Credentials(
    process.env.NEXT_PUBLIC_AWS_ACCESS_KEY,
    process.env.NEXT_PUBLIC_AWS_SECRET_KEY
  ),
})

const personalizeEvents = new AWS.PersonalizeEvents()

export const personalizeClient = new AWS.PersonalizeRuntime()

export default personalizeEvents
