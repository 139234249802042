import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { CategoriesBreakPoints } from "@/constants/BreakPoints";
import { Swiper, SwiperSlide } from "swiper/react";
import { useRouter } from "next/router";
import { useState } from "react";

import { event_category_viewed } from "@/utils/gtm";
import { deviceSwitcherKey } from "@/utils/home";
import Container from "./Container";
import NextImage from "../NextImage";
import Link from "next/link";

function Categories({ data, isMobile }) {
  const [my_swiper, set_my_swiper] = useState({});
  const { locale } = useRouter();
  const filtered_categories = data?.value
    ?.filter((cat) => cat[`${isMobile ? "mobile-" : ""}active`])
    ?.sort((a, b) => +a?.position - +b?.position);
  const categories = [...filtered_categories, ...filtered_categories];

  console.log("categories", categories?.length);
  return (
    categories?.length > 0 && (
      <Container className="my-10 bg-white rounded-md max-md:my-4">
        <div className="relative">
          <Swiper
            className="mx-10"
            loop
            onInit={(ev) => set_my_swiper(ev)}
            breakpoints={CategoriesBreakPoints}
            dir={locale === "ar" ? "rtl" : "ltr"}
            key={locale}
          >
            {categories?.map((category) => (
              <SwiperSlide key={category?.id}>
                <Link
                  href={category["category-url"] || "/"}
                  onClick={() => {
                    if (category?.id) {
                      event_category_viewed(
                        category["title-" + locale],
                        category?.id,
                        null,
                        category?.url
                      );
                    }
                  }}
                  className="flex flex-col items-center justify-center max-[768px]:items-center max-lg:items-start duration-200 hover:scale-[105%] py-[.5rem] cursor-pointer"
                >
                  <div className="relative overflow-hidden md:h-32 md:aspect-square">
                    <NextImage
                      src={category[deviceSwitcherKey("img", isMobile, locale)]}
                      className="object-cover w-full h-auto max-md:w-28 max-md:h-28"
                      height={150}
                      width={150}
                      alt="Cat Img!"
                    />
                  </div>
                  <h5 className="my-3 font-semibold text-center max-md:text-xs">
                    {category["title-" + locale]}
                  </h5>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
          {categories?.length > 7 && (
            <div
              className={`absolute top-1/2 -translate-y-1/2 hidden w-full md:flex justify-between items-center ${
                locale === "ar" ? "flex-row-reverse" : ""
              }`}
            >
              <button
                aria-label="Left Button"
                onClick={() =>
                  locale === "ar"
                    ? my_swiper?.slideNext()
                    : my_swiper?.slidePrev()
                }
              >
                <IoIosArrowBack
                  size="27px"
                  className={`${
                    process.env.NEXT_PUBLIC_WEBSITE_MODE == "extreme" &&
                    "text-white"
                  }`}
                />
              </button>
              <button
                aria-label="Left Button"
                onClick={() =>
                  locale === "ar"
                    ? my_swiper?.slidePrev()
                    : my_swiper?.slideNext()
                }
              >
                <IoIosArrowForward
                  size="27px"
                  className={` ${
                    process.env.NEXT_PUBLIC_WEBSITE_MODE == "extreme" &&
                    "text-white"
                  }`}
                />
              </button>
            </div>
          )}
        </div>
        <div id="we_recommended_products" />
      </Container>
    )
  );
}

export default Categories;
