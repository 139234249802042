import SwiperCore, { Autoplay, Pagination } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import { BrandsBreakPoints } from "@/constants/BreakPoints";
import { useRouter } from "next/router";

SwiperCore.use([Autoplay, Pagination]);
import { deviceSwitcherKey } from "@/utils/home";
import Container from "./Container";
import NextImage from "../NextImage";
import { validateImgSrc } from "@/utils/global";
import Link from "next/link";

function Brands({ data, isMobile }) {
  const { locale } = useRouter();
  return (
    data?.value?.length > 0 && (
      <Container className="my-10 bg-white rounded-md max-md:my-4">
        <h2 className="max-[570px]:text-lg text-3xl font-semibold cursor-pointer text-blue mb-4 max-md:mb-0.5 p-2">
          {data[`title-${locale}`]}
        </h2>{" "}
        <Swiper
          loop
          slidesPerView={5}
          spaceBetween={20}
          breakpoints={BrandsBreakPoints}
          autoplay={{ delay: 3750 }}
          dir={locale === "ar" ? "ltr" : "rtl"}
          key={locale}
          className="p-2 border rounded-md"
        >
          {data?.value?.map((brand, idx) => (
            <SwiperSlide key={brand?.url + idx}>
              <Link
                href={`/${brand?.url || "/"}`}
                className="relative w-full overflow-hidden"
              >
                <NextImage
                  src={validateImgSrc(
                    brand[deviceSwitcherKey("img", isMobile, locale)]
                  )}
                  className="object-contain w-full h-16 m-auto transition-all duration-500 cursor-pointer max-[360px]:mx-auto hover:opacity-80"
                  alt="Brand logo"
                  height={75}
                  width={150}
                />
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    )
  );
}

export default Brands;
