import HomeAWSProductsSwiper from "@/components/Home/HomeAWSProductsSwiper";
import Brands from "@/components/Home/Brands";
import CategoriesSlider from "@/components/Home/CategoriesSlider";
import CategoriesGallery from "@/components/Home/Electronics";
import HotCategoriesList from "@/components/Home/HotCategoriesList";
import MainSlider from "@/components/Home/MainSlider2";
import TodaysOffer from "@/components/Home/TodaysOffer";

import dynamic from "next/dynamic";
import browserStorage from "store";
import AwsTabs from "@/components/Home/aws/aws-tabs";
const BannersGrid = dynamic(() => import("@/components/Home/BannersGrid"));
const ProductList = dynamic(() => import("@/components/Home/ProductList"));
const HotProductsList = dynamic(() =>
  import("@/components/Home/HotProductsList")
);

export const renderHomeJson = (section, isMobile, t, lang) => {
  const data = section;
  switch (section?.key) {
    case "slider":
      return <MainSlider {...{ data, isMobile, locale: lang }} />;
    case "categories":
      return <CategoriesSlider {...{ data, isMobile }} />;
    case "deals":
      return <TodaysOffer {...{ data, isMobile, t }} />;
    case "banners":
      return <BannersGrid {...{ data, isMobile }} />;
    case "products-list":
      return data?.value?.length ? (
        <ProductList {...{ data, isMobile, t }} />
      ) : null;
    case "brands":
      return <Brands {...{ data, isMobile }} />;
    case "eight-banners":
      return <CategoriesGallery {...{ data, isMobile }} />;
    case "hot-products-list":
      return <HotProductsList {...{ data, isMobile, t }} />;
    case "hot-categories":
      return <HotCategoriesList {...{ data, isMobile, t }} />;
    case "tabs":
      return data?.value?.length ? (
        <ProductList data={data} isMobile={false} isTabbed={true} t={t} />
      ) : null;
    case "aws_tabs":
      return <AwsTabs {...{ data, isMobile, locale: lang }} />;
    case "aws_old":
      return (
        <HomeAWSProductsSwiper
          type={data?.type}
          title={data?.[`title-${lang}`]}
          backgroundColor={data?.["background-color"]}
        />
      );
    case "cart-popup-banner":
      return savePopUpBanner(data, isMobile);
    default:
      return null;
  }
};

export function deviceSwitcherKey(key, isMobile, locale = "ar") {
  switch (key) {
    case "position":
      return isMobile ? "mobile-position" : "position";
    case "active":
      return isMobile ? "mobile-active" : "active";
    case "img":
      return isMobile ? `mobile-img-${locale}` : `img-${locale}`;
    default:
      return "";
  }
}

function savePopUpBanner(data, isMobile) {
  const banners = {
    "img-ar": isMobile
      ? data.value?.at(0)["mobile-img-ar"]
      : data.value?.at(0)["img-ar"],
    "img-en": isMobile
      ? data.value?.at(0)["mobile-img-en"]
      : data.value?.at(0)["img-en"],
  };
  console.log("banners before", banners);
  if (typeof window != "undefined") {
    console.log("banners after", banners);
    browserStorage.set("popup-banner", banners);
  }
}

export function handelHomeJsonLinks(url) {
  if (url?.includes("https://") || url?.includes("http://")) {
    return url;
  } else {
    return `/${url}`;
  }
}

// handle urls based on type
const URLS_TYPE = { category: "category", product: "product", url: "url" };
export const validateUrl = (url, type) => {
  const URL = (url === "" ? "#" : url)?.startsWith("/")
    ? url.replace("/", "")
    : url;

  switch (type) {
    case URLS_TYPE.category:
      return URL;
    case URLS_TYPE.product:
      return URL?.startsWith("product/") ? URL : `product/${URL}`;
    case URLS_TYPE.url:
      return URL;
    default:
      return URL;
  }
};
