import Head from "next/head";
import { useRouter } from "next/router";
const defaultSeoData = {
  ar: {
    title: "المنيع | جولات، الكترونيات، أجهزة منزلية",
    description:
      "تسوق الإلكترونيات، الهواتف الذكية، الأجهزة المنزلية، المزيد من أكثر من 20 علامة تجارية عالمية في المملكة العربية السعودية. احصل على توصيل مجاني للطلبات التي تزيد عن 299 ريال سعودي.",
  },
  en: {
    title: "Almanea | Smartphones, Electronics, Appliances, and More",
    description:
      " Shop electronics, smartphones, appliances, and more from more than 20 international brands in Saudi Arabia. Get free deliver on orders above 299 SAR.",
  },
};

const SEO = ({
  title,
  description,
  keywords,
  image,
  pathname,
  isPLP = false,
  children,
}) => {
  const { locale } = useRouter();
  const siteUrl = process.env.NEXT_PUBLIC_IMAGE_BASEURL || "/";
  const url = `${process.env.NEXT_PUBLIC_DOMAIN}${pathname || ""}`;

  const pageTitle = isPLP ? title : title || defaultSeoData[locale]?.title;
  const pageDescription = isPLP
    ? description
    : description || defaultSeoData[locale]?.description;
  const pageImage = `${siteUrl}/media/catalog/product${image}`;
  const pageUrl = url ? url : siteUrl;

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta name="title" content={pageTitle} />
      <meta name="description" content={pageDescription} />
      <meta name="keywords" content={keywords || ""} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={pageUrl} />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:image" content={pageImage} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={pageUrl} />
      <meta property="twitter:title" content={pageTitle} />
      <meta property="twitter:description" content={pageDescription} />
      <meta property="twitter:image" content={pageImage} />
      {children}
    </Head>
  );
};

export default SEO;
