import React, { useEffect, useState } from "react";
import Container from "../Container";
import useSWRMutation from "swr/mutation";
import {
  BEST_SELLER,
  getAwsPersonalizeProductsV2,
  MOST_VIEWED,
  MY_RECOMMENDATIONS,
} from "@/services/global/aws-personalize";
import { useSession } from "next-auth/react";
import { getCookies } from "cookies-next";
import store from "store";
import { getProductsBySkuV2 } from "@/services/elasticsearch/getProducts";
import { selectedAtteributes } from "@/utils/product";
import ArrowIcon from "@/components/icons/ArrowIcon";
import { hotProductsBreakPoints } from "@/constants/BreakPoints";
import { Swiper, SwiperSlide } from "swiper/react";
import ProductCardSkeleton from "@/components/Helpers/ProductCardSkeleton";
import ProductCard from "@/components/Helpers/ProductCard";
import { useTranslation } from "next-i18next";
function awsType(key) {
  switch (key) {
    case "recommended":
      return MY_RECOMMENDATIONS;
    case "bestselling":
      return BEST_SELLER;
    case "mostviewed":
      return MOST_VIEWED;
    default:
      return null;
  }
}

const AwsTabs = ({ data, isMobile, locale }) => {
  const { t } = useTranslation("common");
  const { data: session } = useSession();
  const { handshake, region_id } = getCookies();
  const sessionId = store.get("sessionId") || "";
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [swiper, set_swiper] = useState({});
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const recommenderId = String(session?.user?.id || sessionId);
  const [active, setActive] = useState(0);
  const [activeCat, setActiveCat] = useState(0);
  const handleFilteredCat = (id, products = []) => {
    const filteredProducts = products.filter(
      (product) => product?._source?.category?.at(1)?.category_id === id
    );
    id === 0
      ? setFilteredProducts(products)
      : setFilteredProducts(filteredProducts);
  };

  const { trigger, isMutating } = useSWRMutation(
    `/tab/${data?.value[active]?.type}/${locale}`,
    async () => {
      return await getAwsPersonalizeProductsV2(
        recommenderId,
        awsType(data?.value[active]?.type)
      );
    },
    {
      onSuccess: async (res) => {
        const skus = [
          ...new Set((res?.itemList || [])?.map((prod) => prod?.itemId)),
        ];
        const { data: products } = await getProductsBySkuV2(skus, handshake, [
          ...selectedAtteributes,
          "category",
          "_category_",
        ]);
        const inStockProducts = products?.filter(
          (product) =>
            product?._source?.[
              region_id && !isNaN(region_id) ? region_id : "1101"
            ]?.value > 0
        );
        const unquieCats = new Set();
        const cats = inStockProducts
          ?.map((product) => product?._source?.category?.at(1))
          .filter((cat) => {
            if (unquieCats.has(cat?.category_id)) {
              return false;
            } else {
              unquieCats.add(cat?.category_id);
              return true;
            }
          });
        setCategories(cats);
        setProducts(inStockProducts);
        handleFilteredCat(0, inStockProducts);
        console.log(
          "res aws skus" + awsType(data?.value[active]?.type),
          inStockProducts,
          cats
        );
      },
      populateCache: true,
    }
  );
  useEffect(() => {
    trigger();
  }, []);
  const handleTabs = (idx) => {
    setActive(idx);
    setActiveCat(0);
    setTimeout(() => trigger(), 200);
  };

  return (
    data?.value?.length > 0 && (
      <Container className="my-10 max-[570px]:mt-4">
        {data?.value?.length > 0 && (
          <nav
            className="flex gap-1 overflow-hidden overflow-x-auto gray-scrollbar"
            aria-label="Tabs"
          >
            {data?.value?.map((tab, idx) => (
              <button
                onClick={() => handleTabs(idx)}
                key={tab?.type}
                className={`shrink-0 rounded-lg p-2 md:text-lg font-medium ${
                  active === idx ? "text-blue font-semibold" : ""
                } hover:bg-gray-50 hover:text-gray-700`}
              >
                {tab?.[`title-${locale}`]}
              </button>
            ))}
          </nav>
        )}

        <div
          className={`py-8 mt-2 ${
            locale == "ar" ? "pr-16" : "pl-16"
          } p-16 rounded-lg  max-[570px]:p-3`}
          style={{
            backgroundColor:
              data?.value?.at(0)?.["background-color"] || "white",
          }}
        >
          {categories?.length > 0 && (
            <div className="overflow-auto whitespace-nowrap gray-scrollbar mb-3">
              {[
                {
                  name: locale === "ar" ? "جميع المنتجات" : "All products",
                  // "title-ar": "جميع المنتجات",
                  // "title-en": "All products",
                  category_id: 0,
                },
                ...categories,
              ]?.map((cat, idx) => (
                <button
                  onClick={() => {
                    setActiveCat(idx);
                    handleFilteredCat(cat?.category_id, products);
                  }}
                  key={idx}
                  className={`me-2 mb-3 px-4 py-2 rounded-full text-xs md:text-sm font-semibold ${
                    idx === activeCat
                      ? "bg-blue text-white"
                      : "bg-gray-200 text-gray-700"
                  }`}
                >
                  {cat?.name || "test"}
                </button>
              ))}
            </div>
          )}
          <div className="col-span-8 my-auto max-[570px]:col-span-full">
            <Swiper
              className="w-full"
              spaceBetween={12}
              onSlideChange={(swiper) => {
                setIsBeginning(swiper.isBeginning);
                setIsEnd(swiper.isEnd);
              }}
              onInit={(swiper) => {
                set_swiper(swiper);
              }}
              dir={locale === "ar" ? "rtl" : "ltr"}
              key={locale}
              breakpoints={hotProductsBreakPoints(true)}
            >
              {isMutating &&
                [1, 2, 3, 4, 5].map((i) => (
                  <SwiperSlide key={i * 3454}>
                    <ProductCardSkeleton />
                  </SwiperSlide>
                ))}
              {filteredProducts?.length
                ? filteredProducts?.map(({ _source: product }) => (
                    <SwiperSlide key={product?.sku}>
                      <ProductCard
                        {...{
                          t,
                          product: product,
                          customHeight: "h-[30rem]",
                        }}
                      />
                    </SwiperSlide>
                  ))
                : null}
            </Swiper>
            {(isMobile
              ? filteredProducts?.length > 2
              : filteredProducts?.length > 4) && (
              <div className="min-[1700px]:max-w-[1500px] m-auto relative hidden min-[992px]:block">
                {(locale === "ar" ? !isEnd : !isBeginning) && (
                  <button
                    onClick={() =>
                      locale === "ar"
                        ? swiper?.slideNext()
                        : swiper?.slidePrev()
                    }
                    className="absolute z-[99] items-center justify-center bg-white w-7 h-7 flex -translate-y-1/2 rounded-full text-tertiary-600 -top-[200px] -left-12 size-10 max-[991px]:flex"
                  >
                    <ArrowIcon className="text-black rotate-180 size-4" />
                  </button>
                )}
                {(locale === "ar" ? !isBeginning : !isEnd) && (
                  <button
                    onClick={() =>
                      locale === "ar"
                        ? swiper?.slidePrev()
                        : swiper?.slideNext()
                    }
                    className="absolute z-[99] items-center bg-white w-7 h-7 flex justify-center -translate-y-1/2 rounded-full text-tertiary-600 -top-[200px] -right-12 size-10 max-[991px]:flex"
                  >
                    <ArrowIcon className="text-black size-4" />
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </Container>
    )
  );
};

export default AwsTabs;
